import memoize from 'lru-memoize';
import { createValidator, required, email, match, minLength } from 'utils/validation';

const registerValidation = createValidator({
  username: [email, required],
  password: [required, minLength(8)],
  passwordConfirm: [required, match('password')]
});

export default memoize(10)(registerValidation);
