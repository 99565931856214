import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { Field, Form } from 'react-final-form';
import * as authActions from 'redux/modules/auth';
import loginValidation from './loginValidation';
import registerValidation from './registerValidation';
import forgotValidation from './forgotValidation';
import styles from './Login.less';

class Login extends Component {
  static propTypes = {
    history: PropTypes.objectOf(PropTypes.any),
    location: PropTypes.objectOf(PropTypes.any),
    user: PropTypes.shape({
      email: PropTypes.string
    }),
    login: PropTypes.func,
    register: PropTypes.func,
    forgot: PropTypes.func,
    feedback: PropTypes.func
  };
  state = {
    authType: 'login',
    phoneNumber: '',
    showPassword: false,
    loginSuccess: false,
    loginNotActivated: false,
    loginError: false,
    forgotSuccess: false,
    forgotError: false,
    registerSuccess: false,
    registerExists: false,
    registerError: false
  };
  onLogin = async values => {
    if (this.state.authType === 'forgot') {
      await this.onForgot(values);
      return;
    }
    try {
      const res = await this.props.login(values);
      this.setState({ loginSuccess: true, loginNotActivated: false, loginError: false, forgotSuccess: false, forgotError: false });
      return res;
    } catch (error) {
      this.setState({ loginSuccess: false, loginNotActivated: error.message === 'Your account is not activated!', loginError: error.message !== 'Your account is not activated!', forgotSuccess: false, forgotError: false });
    }
  };
  onRegister = async values => {
    try {
      values.phone = this.state.phoneNumber;
      const res = await this.props.register(values);
      this.setState({ registerSuccess: true, registerExists: false, registerError: false });
      this.props.feedback({ id: 'app:register', email: values.email, phone: values.phone });
      return res;
    } catch (error) {
      this.setState({
        registerSuccess: false,
        registerExists: error.message === 'This email or phone is already in use!',
        registerError: error.message !== 'This email or phone is already in use!'
      });
    }
  };
  onForgot = async values => {
    try {
      const res = await this.props.forgot(values);
      this.setState({ forgotSuccess: true, forgotError: false, loginSuccess: false, loginNotActivated: false, loginError: false });
      return res;
    } catch (error) {
      this.setState({ forgotSuccess: false, forgotError: true, loginSuccess: false, loginNotActivated: false, loginError: false });
    }
  };
  InputComponent = ({ input, label, meta: { touched, error }, ...rest }) => (
    <div className={styles.row}>
      <input {...input} {...rest} className={touched && error ? styles.labelError : ''} placeholder={label} autoComplete={(input.type === 'email').toString()} />
    </div>
  );
  componentDidMount() {
    if (this.props.location.search === '?subId=register') this.setState({ authType: 'register' });
  }
  render() {
    const logo = require('../../../static/images/logo/Group 1682logo.svg');
    const logo2 = require('../../../static/images/logo/whitelogo.svg');
    return [
      <div key="1" className={styles.content}>
        <div className={styles.inner}>
          <div className={styles.wrap}>
            <div className={styles.authForm}>
              <div className={`${styles.leftSide} ${this.state.authType === 'login' ? styles.mobileWidth : this.state.authType === 'forgot' ? styles.mobileWidth : styles.mobileHide}`}>
                <a className={styles.logo} onClick={() => window.location.href = 'https://superqr.com'}>
                  <img src={this.state.authType === 'login' || this.state.authType === 'forgot' ? logo : logo2} width="154" height="47" alt="mvoter" />
                </a>
                <Form
                  onSubmit={values => this.onLogin(values)}
                  validate={this.state.authType !== 'forgot' ? loginValidation : forgotValidation}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className={styles.form}>
                      <div className={styles.row} key="1">
                        <h2>{this.state.authType === 'login' || this.state.authType === 'register' ? 'Войти' : 'Сбросить пароль'}</h2>
                      </div>
                      <div className={styles.row} key="2">
                        <p className={styles.shortText}>используйте свой аккаунт</p>
                      </div>
                      <div className={styles.row} key="3">
                        <Field type="email" name="username" component={this.InputComponent} label="Email" />
                      </div>
                      {this.state.authType === 'login' || this.state.authType === 'register'
                        ? (
                          <div className={styles.row} key="4">
                            <Field type={this.state.showPassword ? 'text' : 'password'} name="password" component={this.InputComponent} label="Пароль" />
                            <span className={`${styles.eye} ${this.state.showPassword ? styles.showPassword : ''}`} onClick={() => this.setState({ showPassword: !this.state.showPassword })} />
                          </div>
                        )
                        : null}
                      {this.state.loginError
                        && (
                          <div className={styles.row} key="5">
                            <p className={styles.textError}>Неверный логин или пароль</p>
                          </div>
                        )}
                      {this.state.loginNotActivated
                        && (
                          <div className={styles.row} key="6">
                            <p className={styles.textError}>Ваш аккаунт не подтвержден</p>
                          </div>
                        )}
                      {this.state.loginSuccess
                        && (
                          <div className={styles.row} key="7">
                            <p className={styles.textSuccess}>Успешная авторизация</p>
                          </div>
                        )}
                      {this.state.forgotSuccess
                        && (
                          <div className={styles.row} key="8">
                            <p className={styles.textSuccess}>Новый пароль отправлен на почту</p>
                          </div>
                        )}
                      {this.state.forgotError
                        && (
                          <div className={styles.row} key="9">
                            <p className={styles.textError}>Пользователь не найден</p>
                          </div>
                        )}
                      <div className={styles.row} key="10" style={{ margin: '20px 0' }}>
                        {this.state.authType === 'login' || this.state.authType === 'register'
                          ? <span className={styles.forgotLink} onClick={() => this.setState({ authType: 'forgot' })}>Забыли свой пароль?</span>
                          : <span className={styles.forgotLink} onClick={() => this.setState({ authType: 'login' })}>Войти</span>}
                      </div>
                      <div className={styles.row} key="11">
                        <button type="submit" className={styles.button}>{this.state.authType === 'login' || this.state.authType === 'register' ? 'Войти' : 'Сбросить'}</button>
                      </div>
                      <div className={styles.row} key="12" style={{ display: 'none', margin: '28px 0 0' }}>
                        <span className={styles.authSocialTitle}>Войти с помощью</span>
                        <ul className={styles.authSocial}>
                          <li><a href="/" target="_blank"><img src="./images/google.svg" width="30" height="30" alt="google" /></a></li>
                          <li><a href="/" target="_blank"><img src="./images/facebook.svg" width="30" height="30" alt="google" /></a></li>
                        </ul>
                      </div>
                      <div className={`${styles.row} ${styles.mobileVersion}`}>
                        <span className={styles.authSocialTitle}>
                          Еще не зарегистрированы?
                          <span className={styles.forgotLink} onClick={() => this.setState({ authType: 'register' })}>Зарегистрироваться</span>
                        </span>
                      </div>
                    </form>
                  )}
                />
                <div className={`${styles.mask} ${this.state.authType === 'login' || this.state.authType === 'forgot' ? styles.firstState : ''}`}>
                  <div className={`${styles.maskInner} ${this.state.authType === 'login' || this.state.authType === 'forgot' ? styles.secondInnerState : ''}`}>
                    <h2>С возвращением!</h2>
                    <p>У вас уже есть аккаунт?</p>
                    <span className={styles.button} onClick={() => this.setState({ authType: 'login' })}>Войти</span>
                  </div>
                </div>
              </div>
              <div className={`${styles.rightSide} ${this.state.authType === 'register' ? styles.mobileWidth : styles.mobileHide}`}>
                <Form
                  onSubmit={values => this.onRegister(values)}
                  validate={registerValidation}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className={styles.form}>
                      <div className={styles.row} key="1">
                        <h2>Регистрация</h2>
                      </div>
                      <div className={styles.row} key="2">
                        <Field type="email" name="username" component={this.InputComponent} label="Email" />
                      </div>
                      <div className={styles.row} key="3">
                        <InputMask
                          type="text"
                          placeholder="Номер телефона"
                          mask="+7 (999) 999-99-99"
                          onChange={event => this.setState({ phoneNumber: event.target.value })}
                        />
                      </div>
                      <div className={styles.row} key="4">
                        <Field type={this.state.showPassword ? 'text' : 'password'} name="password" component={this.InputComponent} label="Пароль" />
                        <span className={`${styles.eye} ${this.state.showPassword ? styles.showPassword : ''}`} onClick={() => this.setState({ showPassword: !this.state.showPassword })} />
                      </div>
                      <div className={styles.row} key="5" style={{ marginBottom: '20px' }}>
                        <Field type={this.state.showPassword ? 'text' : 'password'} name="passwordConfirm" component={this.InputComponent} label="Подтвердите пароль" />
                        <span className={`${styles.eye} ${this.state.showPassword ? styles.showPassword : ''}`} onClick={() => this.setState({ showPassword: !this.state.showPassword })} />
                      </div>
                      {this.state.registerError
                        && (
                          <div className={styles.row} key="6">
                            <p className={styles.textError}>Произошла ошибка, попробуйте позже</p>
                          </div>
                        )}
                      {this.state.registerExists
                        && (
                          <div className={styles.row} key="7">
                            <p className={styles.textError}>Данный пользователь уже зарегистрирован</p>
                          </div>
                        )}
                      {this.state.registerSuccess
                        && (
                          <div className={styles.row} key="8">
                            <p className={styles.textError}>Регистрация прошла успешно. В ближайшие время вам на почту будет отправлено приглашение!</p>
                          </div>
                        )}
                      <div className={styles.row} key="9">
                        <button type="submit" className={styles.button}>Зарегистрироваться</button>
                      </div>
                      <div className={styles.row} key="10" style={{ display: 'none', margin: '28px 0 0' }}>
                        <span className={styles.authSocialTitle}>Войти с помощью</span>
                        <ul className={styles.authSocial}>
                          <li><a href="/" target="_blank"><img src="./images/google.svg" width="30" height="30" alt="google" /></a></li>
                          <li><a href="/" target="_blank"><img src="./images/facebook.svg" width="30" height="30" alt="google" /></a></li>
                        </ul>
                      </div>
                      <div className={`${styles.row} ${styles.mobileVersion}`} key="11">
                        <span className={styles.authSocialTitle}>
                          Если уже имеете аккаунт войдите в систему:<br />
                          <span className={styles.forgotLink} onClick={() => this.setState({ authType: 'login' })}>Войти</span>
                        </span>
                      </div>
                    </form>
                  )}
                />
                <div className={`${styles.mask} ${this.state.authType === 'register' ? styles.secondState : ''}`}>
                  <div className={`${styles.maskInner} ${this.state.authType === 'login' || this.state.authType === 'forgot' ? '' : styles.firstInnerState}`}>
                    <h2>Добро пожаловать!</h2>
                    <p>Еще не зарегистрированы?</p>
                    <span className={styles.button} onClick={() => this.setState({ authType: 'register' })}>Зарегистрироваться</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ];
  }
}

export default connect(
  state => ({
    user: state.auth.user
  }),
  { ...authActions }
)(Login);
